/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    div: "div",
    a: "a",
    strong: "strong"
  }, _provideComponents(), props.components), {SocialLink} = _components;
  if (!SocialLink) _missingMdxReference("SocialLink", true);
  return React.createElement("section", {
    className: "uk-section uk-background-dark uk-light"
  }, React.createElement("div", {
    className: "uk-position-cover uk-background-nav-wash-large"
  }), React.createElement("div", {
    className: "uk-position-two-column-text-circle-2"
  }), React.createElement("div", {
    className: "uk-container uk-container-medium-large uk-margin-large-top@m uk-margin-top uk-position-relative uk-position-z-index-3"
  }, React.createElement("div", {
    className: "uk-position-contact-separator uk-visible@m"
  }), React.createElement("div", {
    className: "uk-grid uk-grid-large uk-child-width-1-2@m"
  }, React.createElement("div", {
    className: "uk-animation-shear-overflow uk-first-column"
  }, React.createElement(_components.p, {
    className: "uk-style-meta uk-color-tertiary uk-text-meta-central uk-text-13 uk-scrollspy-inview "
  }, "Get in touch"), React.createElement(_components.h2, null, "Interested in the next generation of Carbon Credits?"), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "For the first time, carbon credits are comparable across different projects due to our statistical approach, thus allowing the cost to reflect the true climate benefit of the underlying intervention.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Email: ", React.createElement(_components.a, {
    href: "mailto:4c-info@cst.cam.ac.uk"
  }, React.createElement(_components.strong, null, "4c-info@cst.cam.ac.uk")))), React.createElement(_components.p, {
    className: "uk-style-meta uk-color-tertiary uk-text-meta-central uk-text-13 uk-scrollspy-inview "
  }, "Follow us"), React.createElement(SocialLink, {
    icon: "twitter",
    href: "https://twitter.com/trustedcarbon"
  }, "Twitter"), React.createElement(SocialLink, {
    icon: "linkedin",
    href: "https://www.linkedin.com/company/trustedcarbon/"
  }, "LinkedIn"), React.createElement(SocialLink, {
    icon: "youtube",
    href: "https://youtube.com/@trustedcarbon"
  }, "Youtube")), React.createElement("div", {
    className: "uk-scrollspy-inview "
  }, React.createElement(_components.h2, null, "How can we help you?"), React.createElement(_components.p, {
    className: "uk-style-meta uk-color-quinary uk-text-meta-right uk-text-13 uk-scrollspy-inview "
  }, "Project Creator / Land Owner"), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "PACTs significantly drop the startup cost of designing new projects, and provide a digital framework.")), React.createElement(_components.p, {
    className: "uk-style-meta uk-color-quinary uk-text-meta-right uk-text-13 uk-scrollspy-inview "
  }, "Purchaser / Buyer"), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Buyers can evaluate prospective carbon credits across all dimensions of additionality and risk.")), React.createElement(_components.p, {
    className: "uk-style-meta uk-color-quinary uk-text-meta-right uk-text-13 uk-scrollspy-inview "
  }, "Partner / Investor / Developer"), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Interested in an ethical investment, protecting the environment and preserving our earth for the future generations.")), React.createElement(_components.p, {
    className: "uk-style-meta uk-color-quinary uk-text-meta-right uk-text-13 uk-scrollspy-inview "
  }, "Researcher / student"), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "We are establishing international research collaborations around trusted carbon credits, and welcome enquiries from senior or early-career researchers."))))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
